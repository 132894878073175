<template>
  <div class="earning">
    <!-- <van-nav-bar
      title="云店收益"
      right-text="提现记录"
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
    /> -->
    <van-nav-bar
      title="云店收益"
      left-arrow
      @click-left="onClickLeft"
    />
    <van-notice-bar
      v-show="show"
      wrapable
      :scrollable="false"
      color="#ffffff"
      background="#EE0A24"
      left-icon="warning-o"
      @click="onClickRight"
    >
      {{reason}}
    </van-notice-bar>
    <div class="wallent_content" :style="'margin-top: ' + (screenWidth * 0.1) + 'px;'">
      <van-icon name="gold-coin" color="#FFC300" size="60" />
      <van-cell :border="false" title="可提现金额" title-class="content_text" />
      <van-cell :border="false" title-class="content_money">
        <template #title>
          <span>&yen;</span>{{money}}
        </template>
        <template #label>
          <div class="money_list">
            <div v-if="tixianData.sheng_money > 0">实际到账 {{tixianData.sheng_money}} 元</div>
            <div v-if="tixianData.gei_shifu_money > 0">师父分佣 {{tixianData.gei_shifu_money}} 元</div>
            <div v-if="tixianData.gei_shangji_money > 0">推荐店铺分账 {{tixianData.gei_shangji_money}} 元</div>
          </div>
          <div class="label_class" @click="orderlist">
            <span>订单明细 </span><van-icon name="arrow"></van-icon>
          </div>
        </template>
      </van-cell>
      <van-cell-group :border="false" :style="'margin-top: ' + (screenWidth * 0.15) + 'px;'">
        <van-cell :border="false">
          <template #title>
            <van-button type="success" size="small" @click="tixian">提现到微信钱包</van-button>
          </template>
          <template #label>
            <div style="margin-top: 20px; font-size: 14px; color: #2A82E4" @click="onClickRight">
              提现记录
            </div>
          </template>
        </van-cell>
      </van-cell-group>
    </div>
  </div>
</template>
<script>
export default {
  name: "Earning",
  data() {
    return {
      type: 0,
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      title: "收益提现",
      iswx: false,
      islogin: false,
      money: "0.00",
      tixianData: {},
      show: false, // 错误提示显示
      reason: "有提现申请被驳回，请到提现记录查看详情", // 错误提示内容
      userinfo: {}, // 用户信息
    }
  },
  mounted() {
    console.log("sss");
  },
  activated() {
    this.type = this.$route.query.type;
    if (this.type == 1) {
      this.title = "微信支付收款账户"
    }else if(this.type == 2) {
      this.title = '余额支付收款账户';
    }else if(this.type == 3) {
      this.title = '保障金账户';
    }
    document.title = this.title;
    this.init();
  },
  methods: {
    init() {
      this.getMoney();
      this.getinfor();
    },
    getinfor() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common["Authorization"] = localStorage.getItem("SHOP_UID");
      _that.$axios.defaults.headers["Content-Type"] = "application/x-www-form-urlencoded";
      _that.$axios
        .post(_that.$store.state.domain + 'shopsmobile/user/get_info')
        .then(function(response){
          _that.userinfo = response.data.data;
          console.log(_that.userinfo, "userinfo");
        })
        .catch(function(error){
          console.log(error);
        })
    },
    // 获取可提现余额
    getMoney() {
      let that = null;
      that = this;

      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(that.$store.state.domain + "shopsmobile/order/get_tixian")
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            that.money = res.data.data;
            that.tixianData = res.data;
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    // 返回上一页
    onClickLeft() {
      this.$router.back();
    },
    // 跳转账户明细
    onClickRight() {
      console.log("账户明细");
      this.$router.push({
        name: "Withdraw",
        query: {
          t: new Date().getTime()
        }
      });
    },
    // 订单明细
    orderlist() {
      console.log("订单明细");
      this.$router.push({
        name: "Income",
        query: {
          t: new Date().getTime()
        }
      });
    },
    // 提现按钮
    tixian() {
      // if (this.userinfo.memberid != 69041) {
      //   this.$toast("暂未开通");
      //   return false;
      // }
      if (parseFloat(this.money) > 0) {
        this.$router.push({
          name: "Tixian",
          query: {
            t: new Date().getTime()
          }
        });
      }else {
        this.$toast("暂无可提现金额");
      }
    }
  }
}
</script>
<style lang="less">
.earning {
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  .wallent_content{
    padding-bottom: 60px;
    overflow: hidden;
    .van-cell {
      line-height: 40px;
      .van-cell__title {
        flex: 1;
        text-align: center;
      }
      .content_text {
        padding-top: 0px;
        color: #333;
        font-size: 16px;
      }
      .content_money {
        color: #333;
        font-size: 40px;
        span {
          font-size: 20px;
          margin-right: 5px;
        }
      }
      .money_list{
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 30px;
      }
      .label_class {
        display: flex;
        justify-content:center;
        align-items:center;
        padding-top: 6px;
        span,
        .van-icon {
          color: #333;
          font-size: 14px;
        }

      }
    }
    .van-cell-group {
      .van-cell {
        .van-button {
          width: 200px;
          height: 46px;
          background-color: #07c160;
          border-color: #07c160;
          color: #fff;
          border-radius: 6px;
          font-size: 14px;
        }
      }
    }
  }
}
</style>